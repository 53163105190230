@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre > code {
    white-space: pre !important;
    margin-top: -1.00em  !important;;
    display: block  !important;;
}

* {
    box-sizing: border-box;
  }

  .threeWorld {
    font-family: inter;
    color: black;
    background: #f0f0f0;
  }
  
  h2 {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    font-weight: 600;
    font-size: 7em;
    letter-spacing: -0.075em;
    white-space: nowrap;
  }

  @media only screen and (max-width: 600px) {
    h2 {
        font-size: 2em;
    }
  }
